.nav {
    padding: 40px 40px 0 40px;
    z-index: 90;
}
.nav-title {
    font-size: 2.8rem;
    font-family: Misto !important;
    font-weight: bold;

    /* color: rgba(0,0,0,0.6); */
    text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
    color:  #c2fbd7;

    animation: flicker 1.6s infinite alternate;

}

.nav-span {
    font-size: 2.8rem;
    font-family: Misto !important;
    font-weight: bold;
    color: #c2fbd7;
    /* color: rgba(0,0,0,0.6); */
    text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
    animation: flicker 1.5s infinite alternate;

}
.navbar-nav {
    margin-left: 140px;
    column-gap: 25px;
}
.nav-item {
    color: var(--dark-600);
}
.nav-item.active {
    color: var(--green-white-100);
    position: relative;
}
.nav-item.active::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: -30px;
    background: var(--green-400);
    border-radius: 5px;
    transform: rotate(45deg) translateY(-70%);
}
.nav-link {
    color: inherit;
}
.navbar-buttons {
    margin-left: auto;
    column-gap: 20px;
    z-index: 99;
}
.navbar-responsive-menu {
    display: none !important;
}

.button-33 {
    margin: auto;
        background-color: #c2fbd7;
        border-radius: 100px;
        box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
        background-color: var(--green-white-100);
        transition: .4s;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        padding: 7px 20px;
        text-align: center;
        text-decoration: none;
        transition: all 250ms;
        border: 0;
        font-size: 20px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        color: rgb(13, 197, 53);
        font-weight: 600;

      }

      .button-33:hover {
        box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
        transform: scale(1.05) rotate(-1deg);
      }

@media screen and (max-width: 1100px) {
    .d-none-1100 {
        display: none ;
    }
    .navbar-responsive-menu {
        display: block !important;
    }
    .navbar-nav {
        margin-left: 90px;
    }
}
@media screen and (max-width: 960px) {

    .navbar-nav {
        display: none !important;
    }

    .nav{
        padding: 20px 20px 0 20px;
    }

    .nav-title{
        font-size: 2.1rem;
    }

    .nav-span{
        font-size:2.1rem;
    }

    .button-33{
        font-size: 13px;
        padding: 7px 20px;
    }

  .socials{
  width: 35px !important;
  height: 35px !important;
  }

}


.neonText {
    color: #53f1f4;
    text-shadow:
        0 0 7px #51dd99,
        0 0 10px #57aee7,
        0 0 21px #0bf570,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px rgb(20, 255, 165);
  }


@keyframes flicker {

  0%, 18%, 22%, 25%, 53%, 47% {

      text-shadow:
      0 0 4px #6bea9a,
      0 0 11px #5ff3ce,
      0 0 19px #11d16a,
      0 0 40px #0fa,
      0 0 80px rgb(21, 145, 104),
      0 0 90px rgb(10, 130, 90),
      0 0 100px rgb(19, 194, 136),
      0 0 150px rgb(20, 211, 106);

  }

  20%, 24%, 30% {
      text-shadow: none;
  }
}

