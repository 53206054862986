@import "./css-reset.css";



:root {
    --white-100: #FFFFFF;
    --dark-900: #09090A;
    --dark-600: #808090;
    --green-400: #00FF47;
    --green-white-100: #C3EFC0;
}

body {
    background: var(--dark-900);
}

/* my own classes */
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}


@font-face {
    font-family: Misto;
    src: url("./fonts/psyche/2Psyche-Regular.otf") format("opentype");
    font-weight: bold;
   }


   @font-face {
    font-family: 'CustomFont'; /* Specify the desired font-family name */
    src: url('./fonts/liquly/Liquly.ttf') format('truetype'); /* Specify the path to your .ttf file */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Invasion'; /* Specify the desired font-family name */
    src: url('./fonts/invasion2000/INVASION2000.TTF') format('truetype'); /* Specify the path to your .ttf file */
    font-weight: normal;
    font-style: normal;
  }


@font-face {
    font-family: Matrix;
    src: url("./fonts/pakal/Pakal-Light.otf") format("opentype");
    font-weight: normal;
   }
