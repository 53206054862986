.header-box {
    padding: 20px 40px;
    row-gap: 15px;
    text-align: center;

}
.header-box h1 {
    color: var(--white-100);
    font-weight: bolder;
    font-size: 1.2rem;
}
.header-box h3 {
    color: rgba(186, 193, 182, 0.835);
    font-size: 1.1rem;
}
.header-box.active-border-right {
    border-right: 1px solid var(--green-white-100);
}
@media screen and (max-width: 700px) {
    .header-box {
        padding: 10px 15px;
    }

    .header-box h1 {
        color: var(--white-100);
        font-weight: bolder;
        font-size: .9rem;
    }
}


#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
