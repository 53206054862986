.brick-layout {
    position: absolute;
    width: 60%;
    height: 100%;
    top: 0;
    right: -150px;
	display: flex;
    column-gap: 15px;
    overflow: hidden;
}
.brick-layout::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--dark-900);
    opacity: .4;
}
.brick-column {
    width: 100%;
    height: 100%;
}
.brick-column:first-child img:first-child {
    margin-top: -220px;

}
.brick-column:nth-child(2) img:first-child {
    margin-top: -130px;
}
.brick-column:nth-child(2) img:nth-child(2) {
    height: 400px;
}
.brick-column:nth-child(3) img:first-child {
    margin-top: -240px;
}
.brick-column img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 10px;
    animation: primary 3s linear infinite;
}
@media screen and (max-width: 1100px) {
    .brick-layout {
        width: 70%;
    }
}
@media screen and (max-width: 960px) {
    .brick-layout {
        width: 80%;
    }

    .brick-column:nth-child(2) img:first-child {
        margin-top: -300px;
    }
}
@media screen and (max-width: 840px) {
    .brick-layout {
        right: 50%;
        transform: translate(50%, -55%);
        top: 100%;
        border-radius: 10px;
    }
    .brick-layout::before {
        opacity: .6;
    }
}
@media screen and (max-width: 700px) {
    .brick-layout {
        border-radius: 0;
        width: 100%;
    }
}

@keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
