.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* justify-content: center; */
   flex-grow: 1;
    margin-left: -30px;
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 20px;

}





.modal {
    display: flex;

    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
  }

  .modal-content {
    max-width: 80%;
    max-height: 80%



  }

  .close {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 30px;
    color: red;
    cursor: pointer;
  }

  .my-masnry-user-prof-desc {
     align-items: center;
    font-size: 2.5rem;
    text-transform: capitalize;
    gap: .8rem;
}
.my-masnry-user-prof-desc h1 {
    color: var(--green-400);
}
.my-masnry-user-prof-desc h3 {
    color: var(--dark-600);
}


.my-masnry-user-prof {
    width: 150px;
    height: 150px;
}
.my-masnry-user-prof img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.userInfo{
    display: flex;
    flex-direction: column;
;

}

.userInfo > h3 {
    color:white;
}

.modalDes{
display: flex;
flex-direction: column;
align-items: center;
margin-right: 10%;



}

.line{
    width: 112px;
    height: 47px;
    border-bottom: 10px solid rgb(61, 224, 39);
    position: relative;
    }






 .spotifyMobile{
    width: 80% !important;
    height: 25% !important;
    margin-top: 5%;
 }
@media  screen and (max-width: 800px) {
    .modal-content {
        max-width: 80%;
        max-height: 600px;
        min-height: 200px;



      }

      .modal{
        flex-direction: column;
      }

      .modalDes{
       margin-right: 0%;
       margin-top: 8%;
       margin-bottom: -5%;

      }

      .my-masnry-user-prof-desc {
        font-size: 1rem;
      }


.close{
    font-size: 20px;
    top: 2%;
}

.line{
    height: 30px;
}


    }
