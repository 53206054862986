.header-wrap-boxes {
    margin-top: 40px;
}
@media screen and (max-width: 700px) {
    .header-wrap-boxes {

        justify-content: center;
        padding-left: 20%;
        padding-right: 20%;
    }
}
